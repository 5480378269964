import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
// import CKEditor from 'ckeditor4-vue';
// 默认主题
// import 'element-ui/lib/theme-chalk/index.css';
// 自定义生成的主题
import '@/assets/style/element-#59B17A/index.css'

// 整合Ckedit富文本
import Ckedit from "@/components/CkeditCom.vue"
Vue.component('Ckedit', Ckedit)

import CKEditor from '@ckeditor/ckeditor5-vue2';
Vue.use(CKEditor)

// v-viewer
import Viewer from 'v-viewer'   //引入命令
import 'viewerjs/dist/viewer.css'   //引入css样式
Vue.use(Viewer)   //全局使用
Viewer.setDefaults({    //设置初始值
  Options: { 'inline': true, 'button': true, 'navbar': true, 'title': true, 'toolbar': true, 'tooltip': true, 'movable': true, 'zoomable': true, 'rotatable': true, 'scalable': true, 'transition': true, 'fullscreen': true, 'keyboard': true, 'url': 'data-source' }
})


// 工具js
import baseTools from './utils/baseTools'


Vue.prototype.$base = baseTools;
Vue.prototype.$bus = new Vue();

Vue.use(ElementUI);
// Vue.use(CKEditor);
Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
