<template>
  <div class="page login">
    <div class="left">

      <img class="logo" src="../../assets/images/logo.png">
      <img class="tree" src="../../assets/images/tree.png">
    </div>
    <div class="right">
      <p class="title" v-show="tab !== 2">欢迎登录</p>
      <div>

        <div class="tabs" v-show="tab !== 2">
          <div class="tab-item" :class="{ 'active': tab === 0 }" @click="changeTab(0)">
            <p>验证码登录</p>
            <div class="active-line"></div>
          </div>
          <div class="tab-item" :class="{ 'active': tab === 1 }" @click="changeTab(1)">
            <p>密码登录</p>
            <div class="active-line"></div>
          </div>
        </div>
        <div class="back-con" v-show="tab === 2">
          <span @click="goBack(1)">
            <i class="el-icon-arrow-left"></i>
            <span>返回登录</span>
          </span>
        </div>
        <div class="tab-panels">
          <div class="panel" v-if="tab === 0">
            <Input1 prop="phone" title="手机号" placeholder="请输入手机号" :error="phoneInfo" @updateData="updateData"
              key="phone">
            </Input1>
            <CodeInput1 prop="code" title="验证码" placeholder="请输入验证码" :error="codeInfo" @updateData="updateData"
              :phone="phone" :type="1" @getCodeHandle="getCodeHandle" key="code"></CodeInput1>
          </div>
          <div class="panel" v-if="tab === 1">
            <Input1 prop="account" title="账号" placeholder="请输入账号" :error="accountInfo" @updateData="updateData"
              key="account"></Input1>
            <Input1 prop="password" title="密码" placeholder="请输入密码" :is-pwd="true" :error="passwordInfo"
              @updateData="updateData" key="password"></Input1>
            <p class="remember">
              <el-checkbox v-model="checked">记住我</el-checkbox>
              <span class="forget" @click="forgetPassword(2)">忘记密码?</span>
            </p>
          </div>
          <div class="panel" v-if="tab === 2">
            <Input1 prop="phone" title="手机号" placeholder="请输入手机号" :error="phoneInfo" @updateData="updateData"
              key="phone1"></Input1>
            <CodeInput1 prop="code" title="验证码" placeholder="请输入验证码" :error="codeInfo" @updateData="updateData"
              key="code1" :phone="phone" :type="2" @getCodeHandle="getCodeHandle"></CodeInput1>
            <Input1 prop="password" title="密码" placeholder="请输入新密码" :is-pwd="true" :error="passwordInfo"
              @updateData="updateData" key="password1"></Input1>
            <Input1 prop="passwordCopy" title="确认密码" placeholder="请再次输入新密码" :is-pwd="true" :error="passwordCopyInfo"
              @updateData="updateData" key="passwordCopy"></Input1>
          </div>
        </div>
        <div class="button" v-show="tab !== 2" @click="login">登录</div>
        <div class="button" v-show="tab === 2" @click="resetPassword">重置</div>
        <div class="announce" v-show="tab !== 2">本系统为内部系统，暂不开放注册，如有需要请联系管理员申请账号</div>
        <div class="announce" v-show="tab === 2">使用注册手机号接收验证码并重置密码</div>
      </div>
      <span class="copyright">版权所有（c）中国检验检疫科学研究院 备案号：<a href="https://beian.miit.gov.cn" class="link"
          target="_blank">浙ICP备2023012803号</a></span>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Input1 from "@/components/Input1.vue"
import CodeInput1 from "@/components/CodeInput1.vue"
import {
  toLoginPassword,
  toLoginCode,
  regetPassword
} from "@/api/UserHttp.js"
export default {
  name: 'Login',
  components: {
    Input1,
    CodeInput1
  },
  data() {
    return {
      tab: 0,
      phone: "",
      code: "",
      account: "",
      password: "",
      passwordCopy: "",
      phoneInfo: "",
      codeInfo: "",
      accountInfo: "",
      passwordInfo: "",
      passwordCopyInfo: "",
      checked: false
    }
  },
  watch: {
    tab() {
      // this.phone = "";
      // this.code = "";
      // this.account = "";
      // this.password = "";
      // this.passwordCopy = "";
      // this.phoneInfo = "";
      // this.codeInfo = "";
      // this.accountInfo = "";
      // this.passwordInfo = "";
      // this.passwordCopyInfo = "";
      // this.checked = false;
    }
  },
  created() {
    // 绑定事件
    window.addEventListener('keydown', this.keyDown)
  },
  mounted() {
    document.title = '登录 - 多模态特征数据大数据存储平台';
  },
  destroyed() {
    /* 销毁监听 */
    window.removeEventListener('keydown', this.keyDown, false)
  },
  methods: {
    changeTab(tab) {
      if (this.tab === tab) return;
      this.tab = tab;
      this.resetData();
    },
    updateData(obj) {
      this.$data[obj.prop] = obj.data
    },
    forgetPassword(tab) {
      this.tab = tab;
    },
    goBack(tab) {
      this.tab = tab;
    },
    resetData() {
      this.phone = '';
      this.code = '';
      this.account = '';
      this.password = '';
      this.passwordCopy = '';
      this.phoneInfo = '';
      this.codeInfo = '';
      this.accountInfo = '';
      this.passwordInfo = '';
      this.passwordCopyInfo = '';
      this.checked = false;
    },
    // 键盘事件 
    keyDown(e) {
      // 如果是回车则执行登录方法
      if (e.keyCode == 13) {
        if (this.tab === 2) {
          this.resetPassword();
        }
        else {

          this.login();
        }
      }
    },
    // 点击登录按钮
    login() {
      if (this.tab === 0) {
        this.loginCode();
      }
      else if (this.tab === 1) {
        this.loginPassword();
      }
    },
    // 账号密码登录
    loginPassword() {
      if (!this.account || !this.account.replace(/\s+/, '')) {
        this.accountInfo = '账号不能为空';
        return
      }
      if (!this.password || !this.password.replace(/\s+/, '')) {
        this.passwordInfo = '密码不能为空';
        return
      }
      let params = {
        username: this.account,
        password: this.password
      }
      toLoginPassword(params).then(res => {
        // console.log(res)
        if (res && res.code === '0' && res.data) {
          let response = res.data;
          if (response.access) {
            this.$base.storage.setStorage('userToken', response)
            const url = this.$route.query.back
            if (url && url.indexOf('login') == -1) {
              this.$router.replace(url)
            } else {
              this.$router.replace('/')
            }
          }
        }
      })
    },
    // 获取验证码回调
    getCodeHandle(data) {
      this.phoneInfo = data;
    },
    // 手机验证码登录
    loginCode() {
      let reg_tel = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;  //11位手机号码正则
      if (!this.phone || !this.phone.replace(/\s+/, '')) {
        this.phoneInfo = '手机号不能为空';
        return
      }
      else if (!reg_tel.test(this.phone)) {
        this.phoneInfo = '请填写正确手机号！';
        return
      }
      if (!this.code || !this.code.replace(/\s+/, '')) {
        this.codeInfo = '验证码不能为空';
        return
      }
      let params = {
        phone_number: this.phone,
        code: this.code
      }

      toLoginCode(params).then(res => {
        if (res && res.code === '0' && res.data) {
          let response = res.data;
          if (response.access) {
            this.$base.storage.setStorage('userToken', response)
            const url = this.$route.query.back
            if (url && url.indexOf('login') == -1) {
              this.$router.replace(url)
            } else {
              this.$router.replace('/')
            }
          }
        }
      })
    },
    // 重置密码
    resetPassword() {
      this.phoneInfo = "";
      this.codeInfo = "";
      this.passwordInfo = "";
      this.passwordCopyInfo = "";
      let reg_tel = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;  //11位手机号码正则
      if (!this.phone || !this.phone.replace(/\s+/, '')) {
        this.phoneInfo = '手机号不能为空';
        return
      }
      else if (!reg_tel.test(this.phone)) {
        this.phoneInfo = '请填写正确手机号！';
        return
      }
      if (!this.code || !this.code.replace(/\s+/, '')) {
        this.codeInfo = '验证码不能为空';
        return
      }
      if (!this.password || !this.password.replace(/\s+/, '')) {
        this.passwordInfo = '密码不能为空';
        return
      }
      if (!this.passwordCopy || !this.passwordCopy.replace(/\s+/, '')) {
        this.passwordCopyInfo = '确认密码不能为空';
        return
      }
      if (this.passwordCopy !== this.password) {
        this.passwordCopyInfo = '两次密码输入不一致！'
        return
      }
      let params = {
        phone_number: this.phone,
        code: this.code,
        password: this.password
      }
      regetPassword(params).then(res => {
        if (res && res.code === '0' && res.message === 'OK') {
          this.$message.success("重置成功，请登录！");
          this.phoneInfo = "";
          this.codeInfo = "";
          this.passwordInfo = "";
          this.passwordCopyInfo = "";
          this.tab = 1;
        }
      })
    }






  }
}
</script>
<style lang="less" scoped>
.login {

  display: flex;
  color: @themeColor;
  background-color: #fff;
  border-radius: 4px 4px 4px 4px;

  .left {
    flex-shrink: 0;
    flex-grow: 0;
    width: 50%;
    height: 100%;
    background: #EEEFEF;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;

    // div {
    //   flex: 1;
    // }

    img {
      flex: 1;
      overflow: hidden;
      min-width: 0;
      max-width: 95%;
      object-fit: contain;
    }

    .logo {
      // margin-top: 54px;
      // margin-bottom: 100px;
      max-width: 549px;
      max-height: 84px;
    }

    .tree {
      max-width: 584px;
      max-height: 605px;
    }


  }

  .right {
    position: relative;
    flex-shrink: 0;
    flex-grow: 0;
    width: 50%;
    height: 100%;
    // padding-top: 170px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;

    .title {
      text-align: center;
      //margin-bottom: 109px;
      font-size: 36px;
      color: @themeColorBlack;
      font-weight: bold;
    }

    .back-con {
      width: 536px;
      height: 44px;
      line-height: 44px;
      font-size: 18px;
      color: @themeColorBlack;
      cursor: pointer;
      margin: 0 auto;
      margin-bottom: 50px;

      span {
        margin-left: 10px;
      }
    }

    .tabs {
      display: flex;
      justify-content: center;
      margin-bottom: 60px;

      .tab-item {
        position: relative;
        flex-shrink: 0;
        flex-grow: 0;
        height: 44px;
        line-height: 44px;
        cursor: pointer;
        font-size: 18px;
        color: @themeColorGray;

        &.active {
          color: @themeColor;

          .active-line {
            display: block;
          }
        }

        &:first-of-type {
          margin-right: 50px;
        }

        .active-line {
          display: none;
          position: absolute;
          bottom: 0;
          left: 50%;
          transform: translateX(-50%);
          z-index: 1;
          height: 4px;
          width: 30px;
          background-color: @themeColor;


        }
      }
    }

    .tab-panels {
      width: 536px;
      margin: 0 auto;

      .input-item {
        margin-bottom: 20px;
      }

      .remember {
        display: flex;
        justify-content: space-between;
        font-size: 14px;
        color: @themeColorGray;



        .forget {
          cursor: pointer;
        }

      }
    }


    .button {
      width: 536px;
      height: 66px;
      background: @themeColor;
      border-radius: 8px 8px 8px 8px;
      text-align: center;
      margin-left: auto;
      margin-right: auto;
      color: #fff;
      margin-top: 50px;
      line-height: 66px;
      cursor: pointer;
    }

    .announce {
      color: @themeColorGray;
      margin-top: 20px;
      text-align: center;
    }

    .copyright {
      // position: absolute;
      // bottom: 30px;
      // left: 50%;
      // transform: translateX(-50%);
      // z-index: 1;
      display: block;
      text-align: center;
    }
  }

  a.link {
    text-decoration: none;
    color: @themeColorGray;
  }
}
</style>
