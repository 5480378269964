import axios from 'axios'
import { Message } from 'element-ui'
import base from './baseTools'
import router from '../router'
// create an axios instance
const service = axios.create({
  // baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  baseURL: 'https://ismmi-api.hsientang.com/',//环境
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 1000 * 60 * 60 * 6//50000 // request timeout
})
// request interceptor
service.interceptors.request.use(
  config => {
    // console.log("config",config)
    const userToken = base.storage.getStorage('userToken') || {}
    if (userToken.access) {
      config.headers.Authorization = 'Bearer ' + userToken.access || ''
    }
    return config
  },
  error => {
    console.log(error) // for debug
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(

  response => {
    // console.log('resp', response);
    const res = response.data

    // 用户未登录211000,登录过期214000
    if (res && res.code === '211000' || res.code === '214000') {
      Message({
        message: res.message,
        type: 'error',
        duration: 5 * 1000
      })
      console.log('router', router)
      let backUrl = router.history._startLocation;
      const url = `/login?back=${backUrl}`
      router.replace(url)
    }
    else if (res && res.code != '0') {
      if (!response.headers['content-disposition']) {

        Message({
          message: res.message,
          type: 'error',
          duration: 5 * 1000
        })
      }
      else {
        let fileName = response.headers["content-disposition"].split("=")[1];
        return {
          fileName,
          file: res
        }
      }
    }
    return res
    // }
  },
  error => {
    if (error.response) {
      console.log("err1", error.response)

    } else {
      console.log('err' + error) // for debug
      Message({
        message: error.message,
        type: 'error',
        duration: 5 * 1000
      })
      return Promise.reject(error)
    }
  }

)

export default service
