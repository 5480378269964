<template>
  <div class="page home" v-loading.fullscreen.lock="fullscreenLoading">
    <div class="page-title">
      <span class="title-text">我的物种</span>
      <span class="text-total">{{ cardList.length }}个物种，共{{ wholeSize }}</span>
      <el-input placeholder="搜索我的物种" class="only-border" prefix-icon="el-icon-search" v-model="keywords"
        @input="searchUserSpecies">
      </el-input>
      <div class="button-primary" @click="openAddDialog">
        <i class="el-icon-plus"></i>
        <span>添加新物种</span>
      </div>
    </div>
    <div class="home-comtent">

      <div class="page-content">
        <div class="operate-con">

          <div class="tabs">
            <div class="tab" :class="{ 'active': tab.code === activeTab.code }" v-for="(tab, index) in tabList"
              :key="index" @click="selectTab(tab)">{{ tab.name }}</div>
          </div>
          <div class="select-con">
            <span>排序方式：</span>
            <el-select v-model="ordering" placeholder="请选择" @change="getUserSpeciesList">
              <el-option v-for="item in sortList" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="card-list container-fluid" id="card-con">
          <div class="row">

            <!-- <div class="card" v-for="(item, index) in cardList" :key="item.id"
              :style="{ 'margin-right': (index + 1) % num === 0 ? '0px' : distance + 'px' }"> -->
            <!-- <div class="col" style="display: flex;align-items: center;justify-content: center;"
              v-for="(item, index) in cardList" :key="item.id"> -->

            <div class="card col" v-for="(item, index) in cardList" :key="item.id">
              <img class="images" :src="item.species_thumbnail" />
              <div class="card-detail">
                <p class="name">{{ item.species_name }}</p>
                <p class="en-name">{{ item.species_sciname }}</p>
                <p class="detail">
                  <span>
                    <img src="../../../assets/images/icon_file.png" class="icon-num" />
                    {{ item.data_count }}个
                  </span>
                  <span>
                    <img src="../../../assets/images/icon_space.png" class="icon-space" />
                    {{ item.formatSize }}
                  </span>
                </p>
                <div class="btn-con">
                  <div class="button-primary2" @click="checkSpeciesList(item)">查看数据</div>
                  <i class="el-icon-more" @click="showMorePanel(item)"></i>
                  <div class="more-list" v-if="item.showMore">
                    <div class="more-item" @click="showDeletConfirm(item)">
                      <i class="el-icon-delete"></i>
                      <span>删除该物种及其所有数据</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- </div> -->
          </div>

        </div>
      </div>
      <Bottom />
    </div>

    <!-- 删除物种弹窗 -->
    <el-dialog title="注意" class="dialog-delete" :visible.sync="deleteDialogShow" width="330px" :show-close="false"
      :full-screen="true" :close-on-click-modal="false">
      <div class="tip-con">
        <img src="../../../assets/images/icon_notice.png" />
        <span>该操作不可恢复，您确定要删除该物种及其所有数据吗？</span>
      </div>
      <div class="dialog-btn-con">
        <div class="button-cancel" @click="cancelHandle">取消</div>
        <div class="button-next" @click="goingHandle">仍旧删除</div>
      </div>
    </el-dialog>

    <!-- 添加新物种弹窗 -->
    <el-dialog title="添加新物种" class="dialog-add-species" :visible.sync="addDialogShow" width="1324px" :full-screen="true"
      center :close-on-click-modal="false" :before-close="beforeClose" :modal-append-to-body="false">
      <el-input placeholder="请输入物种中文名或拉丁名" class="only-border dialog-input" prefix-icon="el-icon-search"
        v-model="keywordsDialog" @input="searchSpecies">
      </el-input>
      <div class="species-list">

        <div class="species-item" v-for="item in speciesList" :key="item.id">
          <img class="species-img" :src="item.thumbnail">
          <div class="species-detail">
            <p>
              <span class="species-name">{{ item.name }}</span>
              <span class="species-en-name">{{ item.sciname }}</span>
              <span class="species-tags"><!-- v-for="(tag, index) in item.tags" :key="index" -->
                <span class="tag">{{ item.kingdom }}; </span>
                <span class="tag">{{ item.phylum }}; </span>
                <span class="tag">{{ item.cls }}; </span>
                <span class="tag">{{ item.order }}; </span>
                <span class="tag">{{ item.family }}; </span>
                <span class="tag">{{ item.genus }}</span>
              </span>
              <span class="button-primary-small" v-if="!item.added" @click="addUserSpecies(item)">添加</span>
              <span class="button-cancel-small" v-if="item.added">已添加</span>
            </p>
            <p>
              <span :class="{ 'show-all': item.showMore }">{{ item.introduction }}</span>
              <el-button type="text" class="custom-color" v-show="!item.showMore"
                @click="collapseDetail(item)">更多详情</el-button>
              <el-button type="text" class="custom-color" v-show="item.showMore"
                @click="collapseDetail(item)">收起</el-button>
            </p>
          </div>
        </div>
      </div>
      <el-pagination background layout="total,prev, pager, next,jumper" :current-page="currentPage"
        :page-size="pageSize" :total="total" @current-change="handleCurrentChange">
      </el-pagination>
    </el-dialog>


  </div>
</template>
<script>
import Input2 from '@/components/Input2.vue'
import CodeInput2 from '@/components/CodeInput2.vue'
import Bottom from '@/components/Bottom.vue'
import {
  getAllGroup,
  querySpeciesList
} from '@/api/SpeciesHttp.js'
import {
  queryUserSpecies,
  insertUserSpecies,
  deleteUserSpecies

} from '@/api/UserSpeciesHttp.js'
export default {
  name: "Home",
  components: {
    Input2,
    CodeInput2,
    Bottom
  },
  data() {
    return {
      fullscreenLoading: false,
      keywords: "",
      tabList: [],
      activeTab: {
        code: '',
        name: '全部'
      },
      sortList: [
        {
          value: 'created',
          label: '创建时间'
        },
        {
          value: 'data_count',
          label: '数据量'
        },
        {
          value: 'data_size',
          label: '数据大小'
        },
      ],
      ordering: 'created',
      cardList: [
        /* {
          id: 1,
          name: "草地贪夜蛾",
          enname: "Spodoptera frugiperda",
          total: "252",
          volume: "1.57G",
          showMore: false,
        } */
      ],
      // 总容量
      wholeSize: '0B',
      // 删除物种弹窗
      deleteDialogShow: false,
      // 选中的物种
      selectSpecies: {},
      // 添加物种弹窗
      addDialogShow: false,
      keywordsDialog: "",
      speciesList: [
        /* {
          name: "草地贪夜蛾",
          enname: "Spodoptera frugiperda",
          tags: ["Anlmalia;", "Insecta;", "Dlptera;"],
          detail: "是多种热带、亚热带水果和蔬菜的重要害虫，1992年被列入《中华人民共和国进境植物检疫危险性病、虫、杂草名录》，口岸检疫截获频率较高,株高可达300厘米，一年生草本植物，株高可达300厘米，浅绿色，茎直立，粗壮，叶片无毛，卵形至菱状卵形，叶基部楔形，叶柄长，纤细。雌雄异株。穗状花序一年生草本植物，株高可达300厘米，浅绿色，茎直立，粗壮，叶片无毛，卵形至菱状卵形，叶基部楔形，叶柄长，纤细。雌雄异株。穗状花序。",
          alreadAdd: false,
          showMore: true,
        },
        {
          name: "草地贪夜蛾",
          enname: "Spodoptera frugiperda",
          tags: ["Anlmalia;", "Insecta;", "Dlptera;"],
          detail: "是多种热带、亚热带水果和蔬菜的重要害虫，1992年被列入《中华人民共和国进境植物检疫危险性病、虫、杂草名录》，口岸检疫截获频率较高,株高可达300厘米，一年生草本植物，株高可达300厘米，浅绿色，茎直立，粗壮，叶片无毛，卵形至菱状卵形，叶基部楔形，叶柄长，纤细。雌雄异株。穗状花序一年生草本植物，株高可达300厘米，浅绿色，茎直立，粗壮，叶片无毛，卵形至菱状卵形，叶基部楔形，叶柄长，纤细。雌雄异株。穗状花序。",
          alreadAdd: true,
          showMore: false,
        }, */
      ],
      currentPage: 1,
      pageSize: 5,
      total: 0,

      distance: 20,
      num: 3
    }
  },

  mounted() {

    document.title = '我的物种 - 多模态特征数据大数据存储平台';
    this.getAllTabs();
    this.getUserSpeciesList();
    // this.setDistance();
    window.addEventListener('resize', () => {
      // this.setDistance();
      this.caculateEleWidth();
    })
  },
  methods: {
    setDistance() {
      let cardListEle = document.getElementById("card-con");
      // console.log("card", cardListEle)
      if (cardListEle) {

        let boxWidth = cardListEle.clientWidth - 8;
        let num = boxWidth / 560;
        this.num = Math.floor(num);
        let remind = boxWidth - 560 * this.num;
        let distance = Math.floor(remind / (this.num - 1));
        this.distance = distance;
      }
      // console.log(boxWidth, this.num, remind, distance)
    },
    // 选择tab
    selectTab(tab) {
      if (tab.code === this.activeTab.code) return;
      this.activeTab = tab;
      this.getUserSpeciesList();
    },
    // 搜索我的物种
    searchUserSpecies() {
      this.getUserSpeciesList();
    },
    // 打开更多面板
    showMorePanel(item) {
      item.showMore = !item.showMore;
    },
    // 点击删除物种按钮
    showDeletConfirm(item) {
      item.showMore = false;
      this.deleteDialogShow = true;
      this.selectSpecies = item;
    },
    cancelHandle() {
      this.deleteDialogShow = false;
    },
    // 删除用户物种
    goingHandle() {

      deleteUserSpecies(this.selectSpecies.code).then(res => {
        if (res && res.code === '0' && res.message === 'OK') {
          this.$message.success('删除成功！');
          this.deleteDialogShow = false;
          this.getUserSpeciesList();

        }
      })
    },
    // 打开添加物种弹窗
    openAddDialog() {
      this.keywordsDialog = "";
      this.currentPage = 1;
      this.total = 0;
      this.addDialogShow = true;
      this.getSpeciesList();

    },
    // 关闭添加物种弹窗
    beforeClose() {
      this.addDialogShow = false;
      this.getUserSpeciesList();
    },
    // 添加物种弹窗内折叠打开
    collapseDetail(item) {
      item.showMore = !item.showMore;
    },
    // 添加物种弹窗内页码改变
    handleCurrentChange(val) {
      // console.log(`当前页: ${val}`);
      this.currentPage = val;
      this.getSpeciesList();
    },
    // 添加物种弹窗内搜索
    searchSpecies() {
      this.currentPage = 1;
      this.getSpeciesList();
    },
    getAllTabs() {
      this.fullscreenLoading = true;
      getAllGroup().then(res => {
        if (res && res.code === '0' && res.data) {
          let response = res.data || [];
          this.tabList.push({
            code: '',
            name: '全部'
          })
          this.tabList = this.tabList.concat(response);
        }
      }).finally(() => {
        this.fullscreenLoading = false;
      })
    },
    // 获取物种列表
    getSpeciesList() {
      let params = {
        keyword: this.keywordsDialog,
        page: this.currentPage,
        page_size: this.pageSize
      }
      this.fullscreenLoading = true;
      querySpeciesList(params).then(res => {
        if (res && res.code === '0' && res.data) {
          let response = res.data || {};
          // console.log("res", response);
          this.speciesList = (response.results || []).map(item => {
            item.showMore = false;
            return item;
          });
          this.total = response.count;
        }
      }).finally(() => {
        this.fullscreenLoading = false;
      })
    },
    // 获取用户物种列表
    getUserSpeciesList() {
      let params = {
        keyword: this.keywords,
        taxon: this.activeTab.code,
        ordering: this.ordering
      }
      this.wholeSize = '0B';
      this.fullscreenLoading = true;
      queryUserSpecies(params).then(res => {
        if (res && res.code === '0' && res.data) {
          let totalSize = 0;
          let response = (res.data || []).map(item => {
            item.showMore = false;
            totalSize += item.data_size;
            item.formatSize = this.$base.size.convertBytesToSize(item.data_size);
            return item;
          });
          this.cardList = response;
          // this.total = response.count;
          if (totalSize > 0) {
            this.wholeSize = this.$base.size.convertBytesToSize(totalSize);
          }
          // setTimeout(() => {

          //计算
          this.caculateEleWidth()
          // }, 500)

        }
      }).finally(() => {
        this.fullscreenLoading = false;
      })
    },
    // 添加用户物种
    addUserSpecies(item) {

      let params = {
        species: item.code,
      }
      insertUserSpecies(params).then(res => {
        if (res && res.code === '0' && res.message === 'OK') {
          this.$message.success('添加成功！');
          this.getSpeciesList();

        }
      })
    },
    // 查看数据
    checkSpeciesList(item) {
      this.$base.storage.setStorage('speicesItem', item);
      this.$router.push(`/data/${item.species_code}`)
    },
    // 计算元素宽度
    caculateEleWidth() {
      this.$nextTick(() => {
        let cardListEle = document.getElementById("card-con");
        let rowEle = document.querySelectorAll("#card-con .row")[0];
        let lineWidth = rowEle.offsetWidth;
        let list = document.querySelectorAll("#card-con .card");
        // console.log("list", list, list[0], list[0].style, list[0].clientWidth);

        console.log("一行宽度", lineWidth);
        let width = 0;
        let length = list.length;
        console.log("len", length);
        //先移除所有设置的max-width样式
        for (let n = 0; n < length; n++) {
          let element = list[n];
          element.style.maxWidth = '';
        }
        //记录开始不一样尺寸的索引
        let index = -1;
        if (length > 0) {
          width = list[0].offsetWidth;
          console.log("第一个宽度", width);
          for (let i = 0; i < length; i++) {
            let eleWidth = list[i].offsetWidth;
            // 暂定误差3px
            if (Math.abs(eleWidth - width) <= 3) {
              continue;
            }
            else {
              index = i;
              break;
            }
          }
          console.log("开始不一样的索引", index);
          /**
           * 通过直接设定剩余的元素最大宽度方式
           */
          if (index != -1) {

            for (let j = index; j < length; j++) {
              let ele = list[j];
              ele.style.maxWidth = width + "px";
            }
          }
          /**
           * 通过填充元素方式：宽度有误差
           */
          /*
          //剩余个数
          let remind = length - index;
          console.log("remind", remind);
          //找出一行的个数
          let num = -1;
          for (let j = remind + 1; j <= index; j++) {
            if (index % j == 0) {
              let divide = index / j;
              let caclWidth1 = (width + 15) * j;
              let caclWidth2 = (width + 15) * divide;
              // 暂定误差3px
              if (lineWidth - caclWidth1 <= 3) {
                num = j;
                break;
              }
              else if (lineWidth - caclWidth2 <= 3) {
                num = divide;
                break;
              }
            }
          }
          console.log("一行个数", num)
          // 需要填充的个数
          let n = num - remind;
          console.log("填充个数n", n)
          if (n > 0) {
            for (let k = 1; k <= n; k++) {
              let addEle = document.createElement("div");
              addEle.className = "card col new-add";
              addEle.style.width = width + 'px';
              addEle.style.marginRight = '15px';
              rowEle.appendChild(addEle);
            }
          }*/
        }

      })
    }

  }
}
</script>
<style lang="less" scoped>
.page-title {
  width: 100%;
  height: 80px;
  line-height: 44px;
  background-color: #fff;
  padding: 16px 40px;

  .title-text {
    font-size: 22px;
    color: #0B1330;
    font-weight: bold;
  }

  .text-total {
    font-size: 14px;
    color: @themeColorGray2;
    margin-left: 20px;
    margin-right: 110px;
  }

  /deep/ .el-input {
    width: 500px;

    // .el-input__inner {
    //   border-left: 0;
    //   border-right: 0;
    //   border-top: 0;
    //   border-radius: 0;
    // }
  }

  .button-primary {
    display: inline-block;
    float: right;
    width: 150px;
    height: 45px;
    background: radial-gradient(#8BC19F 0%, #58B07A 100%), #FFFFFF;
    border-radius: 4px 4px 4px 4px;
    color: #fff;
    cursor: pointer;
    text-align: center;
    line-height: 45px;

    .el-icon-plus {
      margin-right: 10px;
      font-size: 18px;
      font-weight: bold;

    }
  }

}

/deep/ .only-border.el-input {
  .el-input__inner {
    border-left: 0;
    border-right: 0;
    border-top: 0;
    border-radius: 0;
  }
}

.home-comtent {
  height: calc(100% - 80px);
  overflow-y: auto;
}

.page-content {
  width: 100%;
  min-height: calc(100% - 40px);
  // height: calc(100% - 80px);
  padding: 35px 40px;



  .operate-con {

    margin-bottom: 30px;
    display: flex;
    justify-content: space-between;

    .tabs {
      .tab {
        display: inline-block;
        width: 93px;
        height: 36px;
        background: #FAFAFB;
        border-radius: 4px 4px 4px 4px;
        border: 1px solid @themeColorGray;
        vertical-align: middle;
        text-align: center;
        line-height: 36px;
        color: @themeColorGray;
        font-size: 16px;
        margin-right: 20px;
        cursor: pointer;

        &.active {
          background: #F4FFF8;
          border: 1px solid @themeColor;
          color: @themeColor;
        }
      }
    }

    .select-con {
      width: 310px;
      flex-shrink: 0;
      flex-grow: 0;
    }

    /deep/ .el-select {
      width: 230px;
      height: 36px;

      .el-input__inner {
        height: 36px;
        border-radius: 4px 4px 4px 4px;
        // border: 1px solid #7B89A6;
        background-color: transparent;
      }
    }
  }

  .card-list {
    display: flex;
    flex-wrap: wrap;
    // justify-content: space-evenly;
    // display: grid;
    /*  声明列的宽度  */
    // grid-template-columns: repeat(3, 560px);
    /*  声明行间距和列间距  */
    // grid-gap: 20px;

    .card {
      // width: 330px;
      // height: 430px;
      // height: 200px;
      // flex-shrink: 0;
      // flex-grow: 0;
      background-color: #fff;
      text-align: center;
      // padding-top: 30px;
      box-shadow: 0px 0px 8px 0px rgba(0, 12, 62, 0.2);
      display: flex;
      flex-direction: row;

      align-items: center;
      justify-content: center;
      padding-left: 36px;
      padding-right: 36px;
      padding-top: 14px;
      padding-bottom: 14px;
      margin-bottom: 15px;
      margin-right: 15px;
      // flex-shrink: 0;
      // flex-grow: 0;
      min-width: 500px !important;

      .images {
        width: 148px;
        height: 148px;
        flex-shrink: 0;
        flex-grow: 0;
        border-radius: 50%;

      }

      .card-detail {
        flex-shrink: 0;
        flex-grow: 0;
        margin-left: 30px;
        text-align: left;
      }

      .name {
        font-weight: bold;
        font-size: 20px;
        color: @themeColorBlack;
        // margin-top: 25px;
      }

      .en-name {
        font-weight: 400;
        font-size: 16px;
        color: @themeColorGray2;
        margin-top: 15px;
        font-style: italic;
        max-width: 210px
      }

      .detail {
        font-size: 14px;
        color: @themeColorGray2;
        margin-top: 15px;
        margin-bottom: 15px;

        img {
          margin-right: 3px;
        }

        span:nth-child(1) {
          margin-right: 10px;
        }
      }

      .btn-con {
        display: flex;
        justify-content: center;

        align-items: center;
        position: relative;

        .button-primary2 {
          flex-shrink: 0;
          flex-grow: 0;
          width: 252px;
          height: 40px;
          background: #F4FFF8;
          border-radius: 4px 4px 4px 4px;
          border: 1px solid @themeColor;
          color: @themeColor;
          text-align: center;
          line-height: 40px;
          margin-right: 40px;
          cursor: pointer;
        }

        img {
          cursor: pointer;
        }

        .el-icon-more {
          cursor: pointer;
          font-size: 18px;
          color: @themeColorGray;
          transform: rotate(90deg);

          position: relative;

          &:hover {
            color: @themeColorGray2;
          }
        }

        .more-list {
          position: absolute;
          bottom: -32px;
          right: -175px;
          z-index: 1;
          background: #FFFFFF;
          box-shadow: 1px 0px 9px 0px rgba(0, 0, 0, 0.1);
          border-radius: 6px 6px 6px 6px;
          // transform: rotate(-90deg);
          // position: absolute;
          // top: -110px;
          // left: -65px;

          .more-item {
            width: 220px;
            color: @themeColorRed;
            font-size: 14px;
            line-height: 40px;
            cursor: pointer;
            padding: 0 20px;

            i {
              margin-right: 10px;
            }
          }
        }
      }
    }
  }
}

/deep/ .el-dialog {
  .el-dialog__title {
    font-weight: 400;
    font-size: 16px;
    color: #333333;
    font-weight: bold;
  }

  .tip-con {
    display: flex;

    img {
      flex-grow: 0;
      flex-shrink: 0;
      width: 22px;
      height: 19px;
      margin-right: 5px;
    }

  }

  .dialog-btn-con {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 25px;
  }

  .button-cancel {
    width: 90px;
    height: 30px;
    border-radius: 6px 6px 6px 6px;
    border: 1px solid @themeColorGray2;
    color: @themeColorGray2;
    line-height: 30px;
    text-align: center;
    cursor: pointer;
  }

  .button-next {
    width: 90px;
    height: 30px;
    background: @themeColor;
    border-radius: 6px 6px 6px 6px;
    line-height: 30px;
    text-align: center;
    margin-left: 20px;
    cursor: pointer;
    color: #fff;
  }

  .dialog-input {
    width: 1184px;
    margin-bottom: 30px;

    .el-input__inner {
      border-color: @themeColorGray2;
    }
  }

  .species-list {
    width: 1184px;
    // height: 645px;
    height: 560px;
    overflow-y: auto;
    margin-left: auto;
    margin-right: auto;
    text-align: left;

    .species-item {
      padding-top: 20px;
      padding-bottom: 20px;
      display: flex;
      border-bottom: 1px solid #ECEDF5;

      .species-img {
        width: 70px;
        height: 70px;
        flex-shrink: 0;
        flex-grow: 0;
      }

      .species-detail {
        margin-left: 10px;
        width: calc(100% - 80px);

        p:nth-child(1) {
          margin-bottom: 20px;

          .species-name {
            font-weight: bold;
            font-size: 20px;
            color: @themeColorBlack;
          }

          .species-en-name {
            font-weight: 400;
            font-size: 16px;
            color: @themeColorGray2;
            margin-left: 10px;
            margin-right: 15px;
            font-style: italic;
          }

          .species-tags {
            background: #FAFAFB;
            border-radius: 15px;
            height: 30px;
            line-height: 28px;
            padding: 0 15px;

            .tag {
              font-size: 12px;
              color: @themeColorGray;
            }
          }

          .button-primary-small {
            display: inline-block;
            width: 90px;
            height: 30px;
            line-height: 30px;
            background: @themeColor;
            border-radius: 6px 6px 6px 6px;
            font-weight: 400;
            font-size: 16px;
            color: #fff;
            text-align: center;
            cursor: pointer;
            float: right;
          }

          .button-cancel-small {
            display: inline-block;
            width: 90px;
            height: 30px;
            line-height: 30px;
            border-radius: 6px 6px 6px 6px;
            border: 1px solid @themeColorGray2;
            font-weight: 400;
            font-size: 16px;
            color: @themeColorBlack;
            text-align: center;
            cursor: pointer;
            float: right;
          }
        }

        p:nth-child(2) {
          &>span {
            display: inline-block;
            width: calc(100% - 60px);
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            vertical-align: bottom;

            &.show-all {
              // width: 100%;
              white-space: normal;
              display: inline;
            }
          }

          .custom-color {
            padding: 0;

            span {
              font-size: 14px;
              color: #408BE9;
            }
          }
        }

      }
    }
  }

  .el-pagination {
    margin-top: 20px;
  }

}

/deep/ .el-dialog.el-dialog--center {
  .el-dialog__body {
    text-align: center;
  }
}

/deep/ .dialog-add-species {
  // background-color: rgba(0, 0, 0, 0.6);

  .el-dialog {
    border-radius: 10px 10px 10px 6px;

    .el-dialog__header {
      padding-bottom: 40px;

    }

    .el-dialog__body {
      padding-top: 0;
      padding-bottom: 60px;
    }
  }
}


/deep/ .dialog-delete {
  .el-dialog__header {
    padding-top: 20px;
    padding-bottom: 20px;

  }

  .el-dialog__body {
    padding-top: 0;
    padding-bottom: 36px;
  }
}
</style>